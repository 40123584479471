<template>
  <div>
    <swiper class="swiper" :options="tugeen_delgervvleh_st">
      <!--Company news slide-->
      <swiper-slide  :key="index" v-for="(baiguullaga, index) in ds_baiguullaga">
        <div class="slide_item">
          <div class="b_status" v-if="baiguullaga.torolgishuun">
            <img class="logo" src="../../assets/gold_logo.svg" alt=""
                 v-if="baiguullaga.torolgishuun === 'Алтан багц'">
            <img class="logo" src="../../assets/silver_logo.svg" alt=""
                 v-if="baiguullaga.torolgishuun === 'Мөнгөн багц'">
            <img class="logo" src="../../assets/bronze_logo.svg" alt=""
                 v-if="baiguullaga.torolgishuun === 'Хүрэл багц'">
          </div>
          <div class="b_status" v-else>
            <img class="logo" src="../../assets/company_logo/gish_bus_altan.svg" alt=""
                 v-if="baiguullaga.gishuunbus_torol === 'Алтан багц'">
            <img class="logo" src="../../assets/company_logo/gish_bus_mungun.svg" alt=""
                 v-if="baiguullaga.gishuunbus_torol === 'Мөнгөн багц'">
            <img class="logo" src="../../assets/company_logo/gish_bus_hurel.svg" alt=""
                 v-if="baiguullaga.gishuunbus_torol === 'Хүрэл багц'">
          </div>
          <div class="p_slide_header">
            <div class="company_img">
              <img :src="IMAGE_URL + baiguullaga.logo" alt="" v-if="baiguullaga.logo != ''">
              <img src="../../assets/no-image.png" alt="" v-else>
            </div>
            <div class="company_name">
              <router-link :to="'/baiguullaga_tanilts/' + baiguullaga.id"><p>
                {{ baiguullaga.ner_mn }}</p></router-link>
            </div>
          </div>
          <div class="slide_item_1_1">
            <ul>
              <li v-show="baiIndex <= 2" :key="baiIndex" v-for="(b_zar, baiIndex) in baiguullaga.ds_ajil_bair_zar_view">
                <div>{{ b_zar.ajiliin_torol }}</div>
              </li>
            </ul>
          </div>
          <!--                  <div class="slide_item_1_2">-->
          <!--                    <span>Ажлын байрны зар</span>-->
          <!--                  </div>-->
        </div>
      </swiper-slide>
      <!--Company news slide-->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>

import {GET_BAIGUULLAGA_LIMIT_TEN, IMAGE_URL} from "../../graphql/queries";
export default {
  name: "brigad_slide",
  data () {
    return {
      IMAGE_URL: IMAGE_URL,
      ds_baiguullaga: [],
      tugeen_delgervvleh_st: {
        slidesPerView: 4,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          414: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          375: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 15
          }
        }
      },
    }
  },
  mounted() {
    this.getGet_baiguullaga();
  },
  methods: {
    getGet_baiguullaga() {
      this.$apollo.query({query: GET_BAIGUULLAGA_LIMIT_TEN}).then(({data}) => {
        this.ds_baiguullaga = data.ds_baiguullaga;
      })
    },
  },
}
</script>

<style scoped>

</style>